export const socialsData = {
    github: 'https://github.com/Vamshimandala29',
    // facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/vamshikrishnareddymandala/',
    instagram: 'https://www.instagram.com/vamshi__mandala__/',
    // codepen: 'https://codepen.io/',
    // twitter: 'https://twitter.com/',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',
    // gitlab: 'https://gitlab.com/',
    youtube: 'https://youtube.com/@mandalavamshikrishnareddy4936'
}