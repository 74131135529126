export const experienceData = [
    {
        id: 1,
        company: 'Tata Consultancy Services',
        jobtitle: 'Assistant Systems Engineer',
        startYear: 'August 2022',
        endYear: 'December 2022'
    },
    {
        id: 2,
        company: 'Goal Street',
        jobtitle: 'Web Developer',
        startYear: 'May 2020',
        endYear: 'July 2020'
    },
   
]